/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import dashboardStyle from './DashboardStyle';
import { withStyles } from '@material-ui/core/styles';
import { CssBaseline, Typography, Grid, CircularProgress, IconButton, InputAdornment, Radio } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as APIController from '../../api/APIController';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Scrollbars from 'react-custom-scrollbars';
import Close from '@material-ui/icons/Close';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import SelectDropDown from "../../components/SelectDropDown/SelectDropDown";
import ReviewOrder from './ReviewOrder'
import CommonModal from "../../components/Modal/CommonModal";
import OrderDetails from "./OrderDetails"

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchLoading: false,
            orderLoading: false,
            isCartData: true,
            searchText: '',
            datasourceMedicine: [],
            datasourceStatus: [
                { "ID": "Placed", "Name": "Placed" },
                { "ID": "Approved", "Name": "Approved" },
                { "ID": "Packed", "Name": "Packed" },
                { "ID": "Assigned", "Name": "Assigned" },
                { "ID": "Dispatched", "Name": "Dispatched" },
                { "ID": "Rejected", "Name": "Rejected" },
                { "ID": "Requested", "Name": "Requested" },
                { "ID": "Delivered", "Name": "Delivered" }],
            mediumBrowser: false,
            isLoading: false,
            datasourceStore: [],
            datasourceOrder: [],
            dataOrderFilter: [],
            isReviewOrder: false,
            isSelectStore: false,
            ReviewOrderData: [],
            openDialog: false,
            OrderNO: "",
            OrderID: "",
            StatusFilter: "",
            StoreID: localStorage.getItem("StoreID"),
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        this.wsGetUserStores();
    }

    wsGetCartForDropdown() {
        this.setState({ searchLoading: true, isCartData: true })
        APIController.GetCartForDropdown().then((response) => {
            if (response.object.data != "") {
                this.setState({ datasourceMedicine: response.object.data.Cart, searchLoading: false })
            } else {
                this.setState({ searchLoading: false, datasourceMedicine: [] })
            }
        });

    }

    wsReviewOrder(StoreID) {
        this.setState({ searchLoading: true, isCartData: true })
        APIController.GetCartForDropdown(StoreID).then((response) => {
            if (response.object.data != "") {
                this.setState({ ReviewOrderData: response.object.data, searchLoading: false, isReviewOrder: true })
            } else {
                this.setState({ searchLoading: false })
            }
        });
    }

    wsDeleteCart(ID) {
        this.setState({ searchLoading: true, isCartData: true })
        APIController.DeleteCart(ID).then((response) => {
            ToastsStore.success(response.object.message)
            this.wsSearchMedicines("")
        });
    }

    isReviewOrder() {
        this.setState({ isReviewOrder: false, isSelectStore: false })
        // if (this.state.datasourceStore.length > 1) {
        //     this.setState({ isSelectStore: true })
        // }
        this.wsGetMyOrders()
    }

    wsSearchMedicines(searchText) {
        this.setState({ searchText: searchText })
        if (searchText.length > 3) {
            this.setState({ searchLoading: true })
            APIController.SearchMedicines(searchText).then((response) => {
                this.setState({ datasourceMedicine: response.object.data, searchLoading: false, isCartData: false });
            });
        } else {
            this.setState({ datasourceMedicine: [] })
            // this.wsGetCartForDropdown()
        }
    }

    handleClickSearchText = () => {
        this.setState({ searchText: "", datasourceMedicine: [] });
        this.wsGetCartForDropdown()
    };

    wsAddEditCart(ID, MedicineID, Quantity) {
        APIController.AddEditCart(ID == null ? '0' : ID, MedicineID, Quantity == "" || Quantity == "0" ? "" : Quantity)
            .then((response) => {
                if (response.object.status == 1) {
                    ToastsStore.success(response.object.message)
                    this.wsGetCartForDropdown()
                    this.setState({ searchText: "" })
                    // this.txtMedicines.focus();
                } else {
                    ToastsStore.error(response.object.message)
                    this.wsGetCartForDropdown()
                }
            });
    }

    wsGetUserStores() {
        APIController.GetUserStores().then((response) => {
            this.setState({ datasourceStore: response.object.data });
            localStorage.setItem('StoreID', response.object.data[0].StoreID)
        });
        this.wsGetMyOrders()
    }

    wsGetMyOrders() {
        this.setState({ orderLoading: true })
        APIController.GetMyOrders().then((response) => {
            this.setState({ datasourceOrder: response.object.data, dataOrderFilter: response.object.data, orderLoading: false });
        });
        this.wsGetCartForDropdown()
    }

    handleOrderFilter(OrderStatus) {
        let searchStates = []
        if (OrderStatus == "") {
            this.setState({ datasourceOrder: this.state.dataOrderFilter })
        } else {
            this.state.dataOrderFilter.map((item) => {
                if (item.OrderStatus == OrderStatus) { searchStates.push(item) }
            })
            this.setState({ datasourceOrder: searchStates })
        }
    }

    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    handleSubmit() {
    }

    handleChange = (e) => {
        this.setState({ [e.target.value]: e.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }
    handleClose() {
        this.setState({ openDialog: false })
    }
    focus() {
        this.textInput.current.focus();
    }

    searchMedicineList() {
        const { datasourceMedicine, isLoading, searchText, datasourceStore, searchLoading, isCartData } = this.state;
        const { classes } = this.props;
        return (<>
            <Typography className={classes.txtLogin}>Search Medicine</Typography>
            <Grid container style={{ margin: '0 2% 2% 2%', height: 495 }}>
                <FilledTextField autoFocus label={'Enter Medicine'} rows={1} name="Search Medicine" value={searchText}
                    onChange={(e) => this.wsSearchMedicines(e.target.value)}
                    InputProps={{
                        className: classes.textField,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={this.handleClickSearchText} >
                                    <Close />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} />
                <Scrollbars style={{ maxHeight: 385, width: '100%', }}
                    autoHeightMax={800}
                    autoHide hideTracksWhenNotNeeded={true} >
                    {searchLoading ? this.LoadingView() :
                        !datasourceMedicine.length ?
                            <Grid container style={{ width: '100%', bottom: '0%', top: '0%', left: '0%', right: '0%', position: 'absolute' }} direction="row" justifyContent='center' alignItems='center'>
                                <Typography style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Nunito, sans-serif', textAlign: 'center' }}> No Medicine Found </Typography>
                            </Grid>
                            : <Grid item>
                                {datasourceMedicine != null && datasourceMedicine.map((item, index) =>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap="nowrap"
                                        className={(index % 2) ? classes.oddCartManiView : classes.evenCartManiView} style={item.AvailableQuantity < item.Quantity ? { backgroundColor: '#ffe0e0' } : {}}>
                                        <div style={{ width: '65%' }}>
                                            <Grid container direction="row" alignItems="center" >
                                                {isCartData && <IconButton style={{ padding: '5px 0' }} aria-label="delete" onClick={() => this.wsDeleteCart(item.CartID)}>
                                                    <Delete style={{ fontSize: '18px', }} />
                                                </IconButton>}
                                                {item.Clr && <div
                                                    style={{
                                                        height: '10px',
                                                        width: '10px',
                                                        backgroundColor: item.Clr,
                                                        borderRadius: '50%',
                                                        display: 'inline-block',
                                                        marginRight: '6px',
                                                        marginBottom:"2px"
                                                    }}
                                                ></div>}
                                                <Typography noWrap style={{ color: '#262628', fontSize: '14px', fontWeight: '700', fontFamily: 'Nunito, sans-serif', paddingLeft: '5px' }}> {item.Name} </Typography>
                                            </Grid>
                                            <Grid container direction="row" >
                                                <Typography style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif', width: '40%', paddingLeft: '20px' }}>Pack of:&nbsp;{isCartData ? item.PackSize : item.PackSizeNew}</Typography>
                                                {!isCartData && !item.Clr && <Typography style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif', width: '40%' }}>Available:&nbsp;{item.AvailableQuantity}</Typography>}
                                            </Grid>
                                            <Grid container direction="row">
                                                {item.Offer != null && item.Offer != "" && <Typography style={{ color: '#000', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif' }}>&nbsp;{item.Offer}</Typography>}
                                                {item.SpecialOffer != null && item.SpecialOffer != "" && <Typography style={{ color: '#000', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif' }}>&nbsp;{item.SpecialOffer}</Typography>}
                                            </Grid>
                                        </div>

                                        <div style={{ width: '13%', textAlign: 'end' }}>
                                            <input style={{ border: '1px solid #808080', borderRadius: '50px', padding: "5px", width: '70px', textAlign: 'center' }}
                                                type="number" label={"Quantity"} name="Quantity" value={item.Quantity} maxLength="3"
                                                onChange={(e) => { this.setState({ datasourceMedicine: datasourceMedicine.map((item, idx) => idx !== index ? item : { ...item, Quantity: e.target.value }) }) }}
                                                onBlur={() => item.Quantity > 0 && item.AvailableQuantity >= item.Quantity && this.wsAddEditCart(item.CartID, isCartData ? item.MedicineID : item.ID, item.Quantity)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { item.Quantity > 0 && item.AvailableQuantity >= item.Quantity && this.wsAddEditCart(item.CartID, isCartData ? item.MedicineID : item.ID, item.Quantity) }
                                                }} />
                                        </div>

                                        <div style={{ width: '18%', textAlign: 'end' }}>
                                            <Typography style={{ color: '#39A749', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif' }}>Price:&nbsp;₹ {item.Quantity == "" || item.Quantity == "0" ? item.SalePrice : (item.Quantity * item.SalePrice).toFixed(2)}</Typography>
                                            <Typography style={{ color: 'red', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif', textDecorationLine: 'line-through', }}>₹ {item.Quantity == "" || item.Quantity == "0" ? item.MRP.toFixed(2) : (item.Quantity * item.MRP).toFixed(2)}</Typography>
                                        </div>
                                    </Grid>)}
                            </Grid>
                    }
                </Scrollbars>
                <Grid container style={{ width: '100%', }} alignItems="flex-end">
                    {datasourceMedicine.length && isCartData ? datasourceStore.length > 1
                        ? <ButtonBase onClick={() => { this.setState({ isSelectStore: true }) }} title={'Select Store'} isLoading={isLoading} />
                        : <ButtonBase onClick={() => { this.wsReviewOrder(localStorage.getItem("StoreID")); }} title={'Review Order'} isLoading={isLoading} />
                        : <></>}
                </Grid >
            </Grid >
        </>);
    }

    storeList() {
        const { isLoading, datasourceStore, searchLoading, StoreID } = this.state;
        const { classes } = this.props;
        return (<>
            <IconButton aria-label="delete" onClick={() => this.setState({ isSelectStore: false, isReviewOrder: false })}>
                <ArrowBack />
            </IconButton>
            <Typography className={classes.txtLogin}>Select Store</Typography>
            <Grid container style={{ margin: '0 2% 2% 2%', height: 495 }}>
                <Scrollbars style={{ maxHeight: 450, width: '100%', }}
                    autoHeightMax={800}
                    autoHide hideTracksWhenNotNeeded={true} >
                    {searchLoading ? this.LoadingView() :
                        !datasourceStore.length ?
                            <Grid container style={{ width: '100%', bottom: '0%', top: '0%', left: '0%', right: '0%', position: 'absolute' }} direction="row" justifyContent='center' alignItems='center'>
                                <Typography style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Nunito, sans-serif', textAlign: 'center' }}> No Medicine Found </Typography>
                            </Grid>
                            : <Grid item>
                                {datasourceStore != null && datasourceStore.map((item, index) =>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap="nowrap"
                                        style={{ width: '100%', marginBottom: '2px', padding: '5px 10px 5px 5px', borderRadius: '5px', border: '1px solid #808080', overflow: 'hidden' }}>
                                        <div style={{ width: '5%', alignSelf: 'self-start' }}>
                                            <Radio
                                                checked={StoreID === item.StoreID}
                                                onChange={() => { this.setState({ StoreID: item.StoreID }); localStorage.setItem('StoreID', item.StoreID) }}
                                                value={StoreID}
                                                name="radio-buttons"
                                                classes={{ root: classes.radio, checked: classes.checked }}
                                            />
                                        </div>

                                        <div style={{ width: '65%', display: 'grid' }}>

                                            <Typography noWrap style={{ color: '#262628', fontSize: '14px', fontWeight: '700', fontFamily: 'Nunito, sans-serif', paddingLeft: '5px' }}> {item.Name} </Typography>
                                            <label style={{ color: '#262628', fontSize: '14px', fontWeight: '700', fontFamily: 'Nunito, sans-serif', }}>{item.StoreName}</label>
                                            <label>{item.Address1}</label>
                                            <label>{item.Address2}</label>
                                            <label>{item.CityName}</label>
                                            <label>{item.Postcode}</label>
                                            <label>{item.MobileNo}</label>
                                        </div>

                                        <div style={{ width: '18%', textAlign: 'end' }}>
                                        </div>
                                    </Grid>)}
                            </Grid>
                    }
                </Scrollbars>
                <Grid container style={{ width: '100%', }} alignItems="flex-end">
                    {StoreID != "" && <ButtonBase onClick={() => { this.setState({ isSelectStore: false }); this.wsReviewOrder(StoreID); }} title={'Review Order'} isLoading={isLoading} />}
                </Grid >
            </Grid >
        </>
        );
    }

    ordersList() {
        const { datasourceOrder, orderLoading } = this.state;
        const { classes } = this.props;
        return (
            <Grid container style={{ margin: '0 3% 3%' }}>
                <Scrollbars style={{ height: 485, width: '100%', }}
                    autoHeightMax={600}
                    autoHide hideTracksWhenNotNeeded={true} >
                    {orderLoading ? this.LoadingView() :
                        !datasourceOrder.length ?
                            <Grid container style={{ width: '100%', bottom: '0%', top: '0%', left: '0%', right: '0%', position: 'absolute' }} direction="row" justifyContent='center' alignItems='center'>
                                <Typography style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Nunito, sans-serif', textAlign: 'center' }}>No Order Found</Typography>
                            </Grid>
                            : <Grid item>
                                {datasourceOrder != null && datasourceOrder.map((item, index) =>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap="nowrap"
                                        className={(index % 2) ? classes.oddOrderManiView : classes.evenOrderManiView}
                                        onClick={() => { this.setState({ OrderID: item.ID, OrderNO: item.OrderReferenceNo, openDialog: true }) }}>
                                        <Grid container direction="column"
                                            style={window.innerWidth <= 920 ? { width: '80%', margin: '5px' } : { width: '80%', margin: '5px' }}>
                                            <Typography noWrap style={{ color: '#262628', fontSize: '14px', fontWeight: '700', fontFamily: 'Nunito, sans-serif' }}> {item.StoreName} </Typography>
                                            <Typography style={{ fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif' }}>Order At {item.OrderAt}&nbsp;&nbsp;</Typography>
                                            <Typography style={{ fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif' }}>Estimated Delivery Date {item.EstimatedDeliveryDate}</Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography noWrap className={
                                                item.OrderStatus == "Placed" ? classes.OrderStatusPlaced
                                                    : item.OrderStatus == "Packed" ? classes.OrderStatusAssigned
                                                        : item.OrderStatus == "Dispatched" ? classes.OrderStatusAssigned
                                                            : item.OrderStatus == "Delivered" ? classes.OrderStatusApproved
                                                                : item.OrderStatus == "Rejected" ? classes.OrderStatusRejected
                                                                    : item.OrderStatus == "Cancelled" && classes.OrderStatusRejected}> {item.OrderStatus} </Typography>
                                            <Typography noWrap style={{ color: '#262628', fontSize: '14px', fontWeight: '700', fontFamily: 'Nunito, sans-serif', textAlign: 'end', marginRight: '10px' }}> #{item.OrderReferenceNo} </Typography>
                                        </Grid>
                                    </Grid>)}
                            </Grid>
                    }
                </Scrollbars>
            </Grid>
        );
    }

    LoadingView() {
        return (
            <Grid container style={{ width: '100%' }} direction={'row'} justifyContent={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }

    render() {
        const { mediumBrowser, isReviewOrder, isSelectStore, StatusFilter } = this.state;
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />
                <Header history={this.props.history} browser={mediumBrowser} />
                <ToastsContainer store={ToastsStore} />
                <main className={classes.main}>
                    <Grid container style={{ width: '100%' }} justifyContent='space-around' alignItems='flex-start'>
                        <Grid container className={classes.mainContainer} style={mediumBrowser ? { width: '100%' } : { width: '48%', margin: '1% 0' }} >
                            {!isReviewOrder ? isSelectStore ? this.storeList() : this.searchMedicineList()
                                : <ReviewOrder classes={classes} isReviewOrder={this.isReviewOrder.bind(this)} MedicineData={this.state.ReviewOrderData} searchLoading={this.state.searchLoading} />}
                        </Grid>
                        <Grid container direction='row' alignItems='center' justifyContent='space-between'
                            className={classes.mainContainer} style={mediumBrowser ? { width: '100%' } : { width: '48%', margin: '1% 0' }} >
                            <Typography className={classes.txtLogin}>My Orders</Typography>
                            <div style={{ width: '20%', marginRight: '3%' }}>
                                <SelectDropDown placeholder={"All"} value={StatusFilter} name={"StatusFilter"}
                                    onChange={(e) => { this.setState({ StatusFilter: e.target.value }); this.handleOrderFilter(e.target.value) }}
                                    options={this.state.datasourceStatus} />
                            </div>
                            {this.ordersList()}
                        </Grid>
                    </Grid>
                </main>
                {this.state.openDialog &&
                    <CommonModal
                        maxWidth={'md'}
                        Title={"Order Details"}
                        OrderNO={"#" + this.state.OrderNO}
                        onClose={this.handleClose.bind(this)} IsScroll >
                        <OrderDetails onClose={this.handleClose.bind(this)} OrderID={this.state.OrderID} />
                    </CommonModal>}
                <Footer history={this.props.history} browser={mediumBrowser} />
            </React.Fragment>
        );
    }
}

export default withStyles(dashboardStyle)(Dashboard);